.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.margin-top-10px {
    margin-top: 10px;
}

.margin-right-10px {
    margin-right: 10px !important;
}

.ar-group {
    margin: 10px 10px 0px 10px;
}

.bin-button{
    height: 200px;
    width: 100%
}

.bin-button-row{
    margin-top: 2px;
}
